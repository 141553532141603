<template>
  <div>
    <template v-for="field in fields">
      <div v-if="field._type != 'label'" :key="field.id" :id="field._id" :data-name="field._name" :data-type="field._type"
        class="field-container">
        <div v-if="field._type == 'input'">
          <div>{{ field._name }}：</div>
          <el-input v-model="field.value" show-word-limit clearable :maxlength="10"></el-input>
        </div>
        <div v-else-if="field._type == 'multiInput'">
          <div>{{ field._name }}：</div>
          <el-input></el-input>
        </div>
        <div v-else-if="field._type == 'singleCheck'">
          <div>{{ field._name }}：</div>
          <template v-if="radioFields.includes(field._id)">
            <el-radio-group v-model="field.value">
              <el-radio v-for="option in field.options.option" :key="field._id + option._value" :label="option._value">
                {{ option._displayName }}
              </el-radio>
            </el-radio-group>
          </template>
          <template v-else>
            <el-select v-if="field._id == 'p-20000'" v-model="field.value" filterable placeholder="请选择"
              v-el-select-lazyloading="selectLazyLoad">
              <el-option v-for="subOption in newArray" :key="field._id + subOption._value" :value="subOption._value"
                :label="subOption._displayName"></el-option>
            </el-select>
            <el-select v-else v-model="field.value" placeholder="请选择">
              <el-option v-for="subOption in (field.options || {}).option || []" :key="field._id + subOption._value"
                :value="subOption._value" :label="subOption._displayName"></el-option>
            </el-select>
          </template>
        </div>
        <div v-else-if="field._type == 'multiCheck'">
          <div>{{ field._name }}：</div>
          <el-select multiple placeholder="请选择">
            <el-option v-for="subOption in (field.options || {}).option || []" :key="field._id + subOption._value"
              :value="subOption._value">{{ subOption._displayName }}
            </el-option>
          </el-select>
        </div>
        <div v-else-if="field._type == 'complex'" class="complex-container">
          <div class="complex-title">{{ field._name }}</div>
          <item-field :fields="field.fields.field"></item-field>
        </div>
      </div>
      <template v-for="(rule, index) in (field.rules || {}).rule || []">
        <div v-if="rule._name == 'tipRule'" :key="field._id + rule._name" class="tip-rule">
          <span v-if="field.rules.rule.length > 1">{{ index + 1 }}.</span>
          <span v-html="rule._value"></span>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "ItemField",
  props: {
    fields: {
      type: Array,
      require: true,
    },
  },
  mounted() {
  },
  data() {
    return {
      radioFields: [
        "stuffStatus",
        "globalStock",
        "stockType",
        "departurePlace",
        "legalCustoms",
        "deliveryTimeType",
        "imageVideoType",
        "subStock",
        "invoice",
        "warranty",
        "sellPromise",
        "startTime",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.field-container {
  margin-bottom: 10px;
}

.complex-container {
  .field-container {
    display: inline-block;
    margin: 0 10px 10px 0;
    width: 370px;

    >div {
      display: inline-block;
    }
  }
}

.complex-title {
  width: 100%;
  background: #e3f2fd;
  box-sizing: border-box;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
}

.tip-rule {
  font-size: 12px;
  color: #999;
}
</style>
